<template>
  <div>
    <welcome />
    <education />
    <ProfessionalPath />
    <!-- <get-in-touch /> -->
  </div>
</template>

<script>

export default {
  name: "CoreView",

  components: {
    AboutMe: () => import("@/components/AboutMe"),
    GetInTouch: () => import("@/components/GetInTouch"),
    ProfessionalPath: () => import("@/components/ProfessionalPath"),
    RecentProjects: () => import("@/components/RecentProjects"),
    Education: () => import("@/components/Education"),
    Welcome: () => import("@/components/Welcome"),

  },
};
</script>
